
.login-container {
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100%;
  margin-bottom: 50px;
  padding-top: 15px;
}


.layout {
  width: 100%;
  max-width: 100px;

}

.content p,
.content h2 {
  text-align: center;
}
.login-layout{
  margin-top: 10%;
}
.row {
  display: flex;
  justify-content: center;
  margin-left: 38%;
  
}
.login-box {
  background-color: #6c6c6c;
  padding: 55px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  display: flex;
  flex-direction: column; 
}
.form-group input{}
.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
#password,
#username{
  height: 2.8em;
  width: 18em;
}

.custom-label {
  display: inline-block;
  margin-bottom: 5px;
}

.find-group {
  margin: 2.5em 0;
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.login-btt{
padding: 10px;
font-weight: bold;
font-size: 18px;
cursor: pointer;
border: 0 none;
border-radius: 6px;
padding: 10px 20px;
color: #fff;
background-color: #83D3C9;
/* margin-left: 34%; */
}

#username:hover {
  opacity: 0.5;
}
#username:active{
  
}
.form-group input[type="password"][title="비밀번호"]
.form-group input[type="text"][title="아이디"] {
  color: rgb(0, 0, 0); 
}
.form-group input[type="password"],
.form-group input[type="text"] {
  /* background-color: transparent;  */
  background-color:#fafafa;
}
.form-group input[type="password"]:hover,
.form-group input[type="text"]:hover {
  border-color: 3fb1a3; 
  color: 3fb1a3; 
}

.form-group input[type="password"]:focus,
.form-group input[type="text"]:focus {
  border-color: #3fb1a3;
}

.login-btt:hover {
  background-color: #50c2b5;
}
.login-btt:active {
  background-color: #50c2b5;
}

.social-login {
  margin-top: 20px;
  text-align: center;
}

.social-icon a {
  display: inline-block;
  margin-right: 10px;
  text-decoration: none;
}

.social-icon span {
  display: block;
  padding: 5px;
  background-color: #eee;
  border-radius: 4px;
  font-size: 14px;
  
}
.findPd {
  padding: 5px 10px;
  color: rgb(108, 106, 106);
  border-right: 1px solid #ccc; 
  display: inline-block;
  line-height: 6px; 
}
.findPd:hover{
  color: #83d3c9;
}
.find-group a{
  text-decoration: none;
  border-bottom: none;
  }
  .findPd:last-child {
    border-right: none;
  } 
 @media only screen and (max-width: 440px) {
  .find-group {
    font-size: 12px;
  }
}