.FaqUp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .Faq-edit {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    box-sizing: border-box;
  }
  
  .Faq-edit h2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .title-input {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  #Faq-content .ql-container {
    min-height: 350px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  #Faq-content .ql-container .ql-editor.ql-blank::before {
    color: #999;
    font-size: 16px;
  }
  
  #Faq-content .ql-editor img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .button.primary {
    background-color: #83d3c9;
    color: white;
  }
  
  .button.primary:hover {
    background-color: #50c2b5;
  }
  
  .button.secondary {
    background-color: #ddd;
    color: #333;
  }
  
  .button.secondary:hover {
    background-color: #bbb;
  }
  
  @media (max-width: 600px) {
    .Faq-edit {
      width: 90%;
    }
  
    .title-input {
      font-size: 16px;
    }
  
    .button {
      font-size: 14px;
    }
  }
  