.myinfo-container {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.myinfo-title {
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 20px;
  font-size: 28px;
  font-weight: bold;
  user-select: none;
}

.myinfo-context-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 550px;
}

.myinfo-context {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: center;
  margin-top: 10px;
}

.myinfo-context p {
  margin: 0;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myinfo-context strong {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.myinfo-context span {
  padding: 6px;
  /* font-size: 14px; */
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.2s;
  display: inline-block;
  width: 100%;
  max-width: 270px;
  text-align: center;
  white-space: nowrap;
  /* margin-bottom: 10px; */
}

@media screen and (max-width: 730px) {
  .myinfo-context-container {
      padding: 15px;
  }

  .myinfo-title {
      padding: 15px;
  }
}

@media screen and (max-width: 385px) {
  .myinfo-title {
      font-size: 24px;
      padding: 15px;
  }

  .myinfo-context {
      margin-bottom: 10px;
  }

  .myinfo-context p {
      flex-direction: column;
      align-items: flex-start;
  }

  .myinfo-context span {
      margin-top: 5px;
      width: 100%;
      text-align: left;
  }
}
