/* 기본 스타일 */
.editprofile-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.editprofile-title {
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 20px;
  font-size: 28px;
  font-weight: bold;
  user-select: none;
}

.editprofile-context-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.editprofile-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%; 
}

.Profile-strong {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.editprofile-input,
.Profile-type {
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.2s;
}

.editprofile-input:focus,
.Profile-type:focus {
  border-color: #50c2b5;
  outline: none;
}

.editprofile-btn:hover {
  background-color: #50c2b5;
}

/* 반응형 스타일 */
@media screen and (max-width: 730px) {
  .editprofile-context-container {
    padding: 15px;
  }

  .editprofile-btn {
    padding: 12px;
  }
}

@media screen and (max-width: 385px) {
  .editprofile-title {
    font-size: 24px;
    padding: 15px;
  }

  .editprofile-row {
    margin-bottom: 10px;
  }

  .editprofile-btn {
    padding: 10px;
    font-size: 14px;
  }
}
