.cms-sidebar {
  display: block;
  width: 300px; 
  background-color: #4b5767; 
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: 'Arial', sans-serif;
}

#navPanel {
  display: none;
}

.Cms-Aginginplace {
  border-bottom: 2px solid #83D3C9;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 24px;
  color: #83D3C9;
  margin-bottom: 20px;
}

.cms-item {
  list-style: none;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  transition: background-color 0.3s;
}
.CmsSidebar-h2{
  font-weight: 500;
  color: white;
}
.cms-item a {
  font-size: 18px;
  color: #fff;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 500;
}

.cms-item a:hover {
  background-color: #83D3C9;
  color: #fff;
}

.cms-active {
  background-color: #83D3C9;
  color: white; 
  border-left: 5px solid #fff; 
}

.cms-active a {
  color: white;
  font-weight: 500;
}
.Navipanel-h2{
  color: white;
  font-weight: 500;
}
@media screen and (max-width: 816px) {
  body.navPanel-visible #navPanel {
    transform: translateX(0);
  }

  #navPanel {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #4b5767;
    display: block;
    height: 100%;
    left: 0;
    overflow-y: auto;
    padding: 0.75em 1.25em;
    position: fixed;
    top: 0;
    transform: translateX(-275px);
    transition: transform 0.5s ease;
    width: 275px;
    z-index: 10002;
  }

  #titleBar .title {
    color: #393838;
    display: block;
    font-weight: 300;
    height: 44px;
    line-height: 44px;
    text-align: center;
  }

  #titleBar .toggle:before {
    background: #83D3C9;
    color: #ffffff80;
    content: "\f0c9";
    display: block;
    font-family: 'Font Awesome 5 Free';
    font-size: 18px;
    font-weight: 900;
    height: 44px;
    left: 0;
    line-height: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 54px;
  }
}

@media screen and (max-width: 816px) {
  .cms-sidebar {
    display: none;
  }
}
