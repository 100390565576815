/* .nav-pills-step {
  display: flex;
  list-style: none;
  padding: 20px;
  justify-content: center;
  align-items: center; 
}

.nav-item {
  background-color: #f0f0f0;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.nav-item.active {
  background-color:  #e44c65;
  color: white;
}

.num {
  margin-right: 5px;
}

.section2-title{
  border-bottom: 2px solid rgb(122, 120, 120);
  padding-bottom: 15px;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.signup-info {
  width: 500px;
  margin: auto;
  text-align: left;
}

.signup-info div{
  border-bottom: 1px solid rgb(122, 120, 120);
  padding-bottom: 12px;
}

.Section2-container {
  margin-bottom: 10px;
}

.Section2-field {
  margin-top: 5px;
  width: 450px;
  padding: 18px;
  height: 23px;
}

.select-field1{
  padding:15px;
  margin: 5px 0 10px 5px;
  border-top-right-radius: 20px; 
  border-bottom-right-radius: 20px; 
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid rgb(148, 148, 148);
}

.select-field {
  padding:15px;
}

.error-message {
  color: red;
}

.gender-container,
.typeradio-container {
  display: flex;
  justify-content: space-around; 
  margin-bottom: 20px; 
}

.Section2-field,
.select-field {
  margin-right: 0px;
}

.select-field {
  margin-left: 5px; 
}

.gender-container {
  display: flex;
  justify-content: center;
}

.gender-container label {
  margin-right: 20px;
}

.gender-container input[type="radio"] {
  display: none;
}

.gender-container input[type="radio"] + label {
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.gender-container input[type="radio"]:checked + label {
  background-color: #3a3a4a;
  color: white;
}
.section2-textInt input::placeholder{
  color: white;
}




.Section2-field {
  border-radius: 4px; 
  padding: 10px; 
  border: 1px solid #ccc; 
  outline: none; 
  background-color: transparent;
}

.input-email {
  border-top-left-radius: 20px; 
  width: 303px;
  border-bottom-left-radius: 20px; 
}

.select-field {
  border-top-right-radius: 20px; 
  border-bottom-right-radius: 20px; 
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}


.next-button {
  background-color:#e44c65;
  color: rgb(255, 255, 255); 
  border: none; 
  padding: 10px 20px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  font-size: 18px; 
  cursor: pointer; 
  border-radius: 6px; 
  margin-top: 20px;
  font-weight: bold;
}
.next-button:hover {
  color:rgb(212, 211, 211);
}

.Section-btt{
  background-color:#1877F2;
  color: rgb(255, 255, 255); 
  border: none; 
  padding: 10px 20px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  font-size: 18px; 
  cursor: pointer; 
  border-radius: 20px; 
  margin-top: 20px;
  font-weight: bold;
}



  

.type-radio3{
  margin-left: 30px;
}
.type-radio2{
  margin-left: -30px;
}
  .type-radio2,
  .type-radio1,
  .type-radio,
  .gender-radio,
  .gender-radio1{
    border: 1px solid #c1c0c0;
    border-radius: 8px;
    font-size: 20px;
    color: #2f2f2f;
    padding: 5px 0 5px 0;
    background-color:  #ffffff;
    width: 85px;
    height: 28px;
  }
.typeradio-container,
.genderradio-container{
  display: flex;
  margin-bottom: 10px;
}
.type-radio1,
.gender-radio1{
  
  margin-left: 44%;
}
.type-radio,
.gender-radio{
  margin-left: -40%;
}
  .gender-radio input[type="radio"]:checked + label,
  .type-radio input[type="radio"]:checked + label {
    color: rgb(0, 0, 0); 
    font-weight: bold;
    font-size: 16px;
  }



@media only screen and (max-width: 600px) {
  .gender-radio, .type-radio {
    display: block;
    margin-bottom: 10px;
  }

  .gender-radio label, .type-radio label {
    display: inline-block;
    margin-right: 10px;
  }
  
}


@media only screen and (max-width: 830px) {
  .type-radio1,
  .gender-radio1{
    
    margin-left: 300px;
  }
  .type-radio,
  .gender-radio{
    margin-left: -30%;
  }
}
@media only screen and (max-width: 700px) {
  .type-radio1,
  .gender-radio1{
    
    margin-left: 170px;
    font-size: 22px;
  }
  .type-radio,
  .gender-radio{
    margin-left: -30%;
    font-size: 22px;
  }
}
@media only screen and (max-width: 400px) {
  .type-radio1,
  .gender-radio1{
    
    margin-left: 30px;
    font-size: 22px;
  }
  .type-radio,
  .gender-radio{
    margin-left: -15%;
    font-size: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .type-radio1,
  .gender-radio1{
    
    margin-left: 30px;
    font-size: 22px;
  }
  .type-radio,
  .gender-radio{
    margin-left: -15%;
    font-size: 22px;
  }
}
@media only screen and (max-width: 250px) {
  .type-radio1,
  .gender-radio1{
    
    margin-left: -10px;
    font-size: 22px;
  }
  .type-radio,
  .gender-radio{
    margin-left: 1%;
    font-size: 22px;
  }
} */
@media only screen and (max-width: 500px) {
  #Section2Btt{
    width: 19%;
  }
  .button11{
    margin-bottom: 10px;
  }
  /* #role{
    width: 95%;
  } */
  }
  .button11{
    -moz-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      appearance: none;
      -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid rgb(194, 194, 194);
      color: #ffffff !important;
      cursor: pointer;
      display: inline-block;
      font-weight: 300;
      height: 3em;
      line-height: 3em;
      padding: 0 2.25em;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      background-color: #83D3C9;
      font-weight: 400;
      font-size: 14spx;
      font-weight: bold;
  }
  .button11:hover{
    background-color: #50c2b5;
    font-weight: bold;
  }
  #guardianName{
    width: 7em;
    height: 2.4em;
    margin-right: 10px;
  }

  #patientPhoneNumber{
    width: 10.5em;
    margin-right: 10px;
  }
  .button-container {
    display: flex;
    align-items: center;
  }
  
  .section2-rolet {
    font-size: 17px;
    font-weight: bold;
    color: white;
  }
  
  .section2-rolef {
    font-size: 17px;
    font-weight: bold;
    color: red;
  }
  #section2ro{
    margin: 0 0 0.5em;
  }
  #Section2Btt{
    font-weight: bold;
  }
#height-css{
  width: 10em;
  margin-right: 10px;
}
#weight-css{
  width: 10em;
}