.Cms-AddPatientCriteria {
  display: flex;
  min-height: 100vh;
}

.cms-AddPatient-content {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 50px;
  margin: 30px auto;
  width: 80%;
  max-width: 1000px;
}

.Cmss-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.Cmss-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.criteria-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.criteria-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.criteria-item label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.criteria-item input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
}


.saved-criteria {
  margin-top: 30px;
  width: 100%;
  max-width: 600px;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.saved-criteria h3 {
  margin-bottom: 15px;
  color: #333;
}

.saved-criteria p {
  margin-bottom: 10px;
  color: #555;
}

#nowrap {
  white-space: nowrap;
}