/* .notice-table{
    margin: 50px auto 10px; 
    width: 50%;
    margin-left: 37%;
}

.notice-table th {
    font-weight: bold; 
    font-size: 1px; 
}

.notice-table td {
    font-size: 20px; 
}

.notice-table table {
    width: 100%;
    border-collapse: collapse; 
  }
  .notice-table th,
  .notice-table td {
  padding: 1px; 
  text-align: center; 
  border: none; 
}

.notice-table th {
  background-color: #ffffff; 
}


.notice-table td {
  border-top: 1px solid #ddd; 
}

.notice-table tr:last-child td {
  border-bottom: 2px solid #ddd; 
}

.notice-table th{
  border-top: 3px solid #000000; 
  font-size: 24px;
  padding: 20px;
}

@media only screen and (max-width: 768px) {

    .qna-options{
      margin-top: 320px;
    }
    .qna-header {
      margin-left: 0;
    }
    .notice-table {
      margin-left: 0px;
      margin-top: 35px;
      margin-right: auto;
      width: 40%;
      
    }
}
@media only screen and (max-width: 600px) {
  .notice-table th,
  .notice-table td {
    font-size: 10px;
  }
} */

.notice-table td{
  cursor: pointer;
}
