/* FAQ 컨테이너 */
.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
    margin-top: 50px;
  }
  
  /* FAQ 타이틀 */
  .faq-title {
    margin-bottom: 20px;
  }
  
  .faq-title h2 {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  /* FAQ 내용 */
  .faq-content {
    width: 100%;
  }
  
  /* FAQ 항목 */
  .faq-item {
    border: 1px solid #ddd;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    transition: background-color 0.3s;
  }
  
  .faq-item:hover {
    background-color: #f9f9f9;
  }
  
  /* FAQ 질문 */
  .faq-question {
    font-weight: bold;
    color: #333;
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }
  
  /* FAQ 답변 */
  .faq-answer {
    color: #555;
    padding: 5px;
    margin-top: 10px;
    display: none;
  }
  
  .faq-answer.visible {
    display: block;
  }
  
  /* 미디어 쿼리 */
  @media (max-width: 600px) {
    .faq-container {
      padding: 10px;
    }
  
    .faq-title h2 {
      font-size: 24px;
    }
  
    .faq-item {
      padding: 10px;
    }
  
    .faq-question,
    .faq-answer {
      font-size: 14px;
    }
  }
  