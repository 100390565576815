#PatientDetail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.X-Button {
    padding: 5px 10px;
    border: none;
    background-color: #ff4d4d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.X-Button:hover {
    background-color: #ff1a1a;
}

#dosage, #frequency, #diagnosis, #medication {
    width: 80%;
    max-width: 300px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.diagnosis-item, .medication-item {
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-bottom: 10px;
    width: 100%;
    max-width: 600px;
}

.medication-item input, .diagnosis-item input {
    flex: 1;
    margin-right: 10px;
}

#CmsAdd-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Cms-diagnosis-container, .Cms-medication-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.Medication-saveBtt, .Diagnosis-saveBtt, .saveBtt {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.diagnosis-item input, .medication-item input {
    margin-bottom: 0; 
}

.primary-button {
    padding: 10px 20px;
    background-color: #0275d8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
}

.primary-button:hover {
    background-color: #025aa5;
}

.secondary-button {
    padding: 10px 20px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.secondary-button:hover {
    background-color: #4cae4c;
}

#cancelBtt {
    background-color: #bbb;
    /* padding: 10px 20px; */
    border-radius: 5px;
    /* margin-top: 10px; */
}

#cancelBtt:hover {
    background-color: #999;
}

.Cmss-header {
    text-align: center;
    margin-bottom: 20px;
}

.Cmss-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

@media (max-width: 768px) {
    .diagnosis-item, .medication-item {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .X-Button {
        margin-left: 0;
        margin-top: 10px;
    }
    #PatientDetail-Btt, #saveBtt, #cancelBtt {
        width: 100%;
        max-width: none;
    }
}

@media (max-width: 540px) {
    #Patient-input{
        width: 90%;
    }
    #medica-css{
        width: 90%;
    }
}
/* 약물 정보 컨테이너 */
.Cms-medication-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }
  
  /* 약물 입력 필드 */
  .medication-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* 입력 필드 스타일 */
  .medication-item input {
    width: 25%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  /* 알람 시간 입력 필드 */
  
  /* 버튼 크기 및 정렬 */
  .X-Button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .X-Button:hover {
    background-color: #d32f2f;
  }
  
  .saveBtt {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }