/* QnA 페이지 스타일 */
.qna-container {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* padding: 20px; */
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: auto;
    margin-top: 50px;
  }
  
  /* .qna-title {
    margin-bottom: 20px;
  }
  
  .qna-title h2 {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .qna-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .qna-options {
    display: flex;
    align-items: center;
  }
  
  .qna-select {
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .qna-search {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-right: 10px;
  }
  
  .button.primary {
    background-color: #83d3c9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button.primary:hover {
    background-color: #50c2b5;
  }
  
  .qna-content {
    width: 100%;
  }
  
  .qna-content table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .qna-content th, .qna-content td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    background-color: #f0f0f0;
  }
  
  .qna-content th {
    font-weight: bold;
    color: #999;
  }
  
  .qna-content td {
    background-color: white;
  }
  
  .qna-content a {
    color: #333;
    text-decoration: none;
  }
  
  .qna-content a:hover {
    text-decoration: underline;
  }
  
  .answer-row {
    background-color: #f9f9f9;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-button {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px 15px;
    border: none;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .page-button.active {
    background-color: #83d3c9;
    color: white;
  }
  
  .page-button:hover {
    background-color: #50c2b5;
    color: white;
  }
  
  @media (max-width: 600px) {
    .qna-container {
      padding: 10px;
    }
  
    .qna-title h2 {
      font-size: 24px;
    }
  
    .qna-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .qna-options {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .qna-select, .qna-search, .button.primary {
      margin-bottom: 10px;
      width: 100%;
    }
  
    .qna-content table {
      font-size: 14px;
    }
  
    .pagination {
      flex-direction: column;
    }
  
    .page-button {
      margin-bottom: 5px;
      width: 100%;
    }
  } */
  