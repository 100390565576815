
/* Spotlight */
#BaroGOGO{
	font-weight: bold;
	color: #39c088;
}

.spotlight2 {
	background-attachment: fixed;
	background-position: center center;
	background-size: cover;
	box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
	height: 100vh;
	overflow: hidden;
	position: relative;
}

	.spotlight2:nth-last-of-type(1) {
		z-index: 1;
	}

	.spotlight2:nth-last-of-type(2) {
		z-index: 2;
	}

	.spotlight2:nth-last-of-type(3) {
		z-index: 3;
	}

	.spotlight2:nth-last-of-type(4) {
		z-index: 4;
	}

	.spotlight2:nth-last-of-type(5) {
		z-index: 5;
	}

	.spotlight2:nth-last-of-type(6) {
		z-index: 6;
	}

	.spotlight2:nth-last-of-type(7) {
		z-index: 7;
	}

	.spotlight2:nth-last-of-type(8) {
		z-index: 8;
	}

	.spotlight2:nth-last-of-type(9) {
		z-index: 9;
	}

	.spotlight2:nth-last-of-type(10) {
		z-index: 10;
	}

	.spotlight2:nth-last-of-type(11) {
		z-index: 11;
	}

	.spotlight2:nth-last-of-type(12) {
		z-index: 12;
	}

	.spotlight2:nth-last-of-type(13) {
		z-index: 13;
	}

	.spotlight2:nth-last-of-type(14) {
		z-index: 14;
	}

	.spotlight2:nth-last-of-type(15) {
		z-index: 15;
	}

	.spotlight2:nth-last-of-type(16) {
		z-index: 16;
	}

	.spotlight2:nth-last-of-type(17) {
		z-index: 17;
	}

	.spotlight2:nth-last-of-type(18) {
		z-index: 18;
	}

	.spotlight2:nth-last-of-type(19) {
		z-index: 19;
	}

	.spotlight2:nth-last-of-type(20) {
		z-index: 20;
	}

	.spotlight2:before {
		background-image: url("/public/images/555.jpg");
		content: '';
		display: block;
		height: 100%;
		left: 0;
		top: 0;
		width: 100%;
	}

	.spotlight2 .image.main {
		display: none;
	}

		.spotlight2 .image.main img {
			position: relative;
		}

	.spotlight2 .content {
		-moz-transform: translate(0,0);
		-webkit-transform: translate(0,0);
		-ms-transform: translate(0,0);
		transform: translate(0,0);
		-moz-transition: -moz-transform 1s ease, opacity 1s ease;
		-webkit-transition: -webkit-transform 1s ease, opacity 1s ease;
		-ms-transition: -ms-transform 1s ease, opacity 1s ease;
		transition: transform 1s ease, opacity 1s ease;
		background: rgb(205 204 204 / 95%);
		border-style: solid;
		opacity: 1;
		position: absolute;
	}

	.spotlight2 .goto-next {
		-moz-transform: translate(0,0);
		-webkit-transform: translate(0,0);
		-ms-transform: translate(0,0);
		transform: translate(0,0);
		-moz-transition: -moz-transform 0.75s ease, opacity 1s ease-in;
		-webkit-transition: -webkit-transform 0.75s ease, opacity 1s ease-in;
		-ms-transition: -ms-transform 0.75s ease, opacity 1s ease-in;
		transition: transform 0.75s ease, opacity 1s ease-in;
		-moz-transition-delay: 0.5s;
		-webkit-transition-delay: 0.5s;
		-ms-transition-delay: 0.5s;
		transition-delay: 0.5s;
		opacity: 1;
	}

	
	.spotlight2.top .content, .spotlight2.bottom .content {
		left: 0;
		padding: 5.1em 0 3.1em 0;
		width: 100%;
	}

	.spotlight2.top .content {
		border-bottom-width: 0.35em;
		top: 0;
	}

	.spotlight2.bottom .content {
		border-top-width: 0.35em;
		bottom: 0;
	}

	.spotlight2.left .content, .spotlight2.right .content {
		height: 101%;
		padding: 6em 3em;
		top: 0;
		width: 28em;
	}

	.spotlight2.left .content {
		border-right-width: 0.35em;
		left: 0;
	}

	.spotlight2.right .content {
		border-left-width: 0.35em;
		right: 0;
	}

	.spotlight2.style1 .content {
		/* border-color: #e44cred65; */
    border-color: blue;
	}

	.spotlight2.style2 .content {
		border-color: #5480f1;
	}

	.spotlight2.style3 .content {
		border-color: #39c088;
	}

	.spotlight2.inactive .content {
		opacity: 0;
	}

	.spotlight2.inactive .goto-next {
		-moz-transform: translate(0,1.5em);
		-webkit-transform: translate(0,1.5em);
		-ms-transform: translate(0,1.5em);
		transform: translate(0,1.5em);
		opacity: 0;
	}

	.spotlight2.inactive.top .content {
		-moz-transform: translate(0,-5em);
		-webkit-transform: translate(0,-5em);
		-ms-transform: translate(0,-5em);
		transform: translate(0,-5em);
	}

	.spotlight2.inactive.bottom .content {
		-moz-transform: translate(0,5em);
		-webkit-transform: translate(0,5em);
		-ms-transform: translate(0,5em);
		transform: translate(0,5em);
	}

	.spotlight2.inactive.left .content {
		-moz-transform: translate(-5em,0);
		-webkit-transform: translate(-5em,0);
		-ms-transform: translate(-5em,0);
		transform: translate(-5em,0);
	}

	.spotlight2.inactive.right .content {
		-moz-transform: translate(5em,0);
		-webkit-transform: translate(5em,0);
		-ms-transform: translate(5em,0);
		transform: translate(5em,0);
	}
  body.is-touch .spotlight2 {
    background-attachment: scroll;
  }

  @media screen and (max-width: 1280px) {
		
    /* Spotlight */

      .spotlight2.top .content {
        padding: 3.825em 0 1.825em 0;
      }

      .spotlight2.bottom .content {
        padding: 3.825em 0 2.95em 0;
      }

      .spotlight2.left .content, .spotlight2.right .content {
        padding: 4.5em 2.5em;
        width: 25em;
      }
    }
    @media screen and (max-width: 980px) {
  
      /* Spotlight */
  
        .spotlight2 {
          background-attachment: scroll;
          height: auto;
        }
  
          .spotlight2 .image.main {
            display: block;
            margin: 0;
            max-height: 40vh;
            overflow: hidden;
            
          }
  
          .spotlight2.content {
            background-color: #1c1d26;
            border-width: 0 !important;
            border-top-width: 0.35em !important;
            bottom: auto !important;
            left: auto !important;
            padding: 4.5em 2.5em 2.5em 2.5em !important;
            position: relative;
            right: auto !important;
            text-align: center;
            top: auto !important;
            width: 100% !important;
          }
  
            .spotlight2 .content ul.actions {
              -moz-justify-content: center;
              -webkit-justify-content: center;
              -ms-justify-content: center;
              justify-content: center;
              width: 100%;
              margin-left: 0;
            }
  
              .spotlight2 .content ul.actions li:first-child {
                padding-left: 0;
              }
  
          .spotlight2 .goto-next {
            display: none;
          }
        }
    @media screen and (max-width: 980px) {
      /* Spotlight */
  
        .spotlight2.inactive.right {
          background-attachment: scroll;
          height: auto;
        }
  
          .spotlight2 .image.main {
            display: block;
            margin: 0;
            max-height: 40vh;
            overflow: hidden;
            
          }
  
          .spotlight2 .content {
            background-color: #f6f6f6;
            border-width: 0 !important;
            border-top-width: 0.35em !important;
            bottom: auto !important;
            left: auto !important;
            padding: 4.5em 2.5em 2.5em 2.5em !important;
            position: relative;
            right: auto !important;
            text-align: center;
            top: auto !important;
            width: 100% !important;
          }
  
            .spotlight2 .content ul.actions {
              -moz-justify-content: center;
              -webkit-justify-content: center;
              -ms-justify-content: center;
              justify-content: center;
              width: 100%;
              margin-left: 0;
            }
  
              .spotlight2 .content ul.actions li:first-child {
                padding-left: 0;
              }
  
          .spotlight2 .goto-next {
            display: none;
          }
        }
        .spotlight2 {
          box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
        }
  
          .spotlight2 .image.main {
            max-height: 60vh;
          }
  
          .spotlight2 .content {
            border-top-width: 0.2em !important;
            padding: 3.25em 1.5em 1.25em 1.5em !important;
          }
          .spotlight2 .image.main {
            max-height: 50vh;
          }
    
          .spotlight2 .content {
            padding: 3em 1.25em 1em 1.25em !important;
          }
    #main-h2{
      color: #e44c65;
    }
    #main-p{
      /* text-align: left; */
      font-size: 27px;
    }
#four{
  border-top: 0.35em solid #83D3C9;
  margin-bottom: 20px;
}


section.special, article.special {
	text-align: center;
}

header.special {
	padding-top: 3em;
	position: relative;
	text-align: center;
}

	header.special:before, header.special:after {
		border-bottom: solid 1.5px;
		border-top: solid 1.5px;
		content: '';
		height: 7px;
		opacity: 0.1;
		position: absolute;
		top: 1.75em;
		width: 43%;
	}

	header.special:before {
		left: 0;
	}

	header.special:after {
		right: 0;
	}

	.centered-image {
		display: block;
		margin: 0 auto;
		width: 50%;
		max-width: 350px; 
	  }
.Main-TextLo{
	font-size: 28px;
	color: #39c088;
}


/*ㅇㅇ*/
.dementia-info-container {
	font-family: Arial, sans-serif;
	line-height: 1.6;
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
  }
  
  .section {
	margin-bottom: 30px;
  }
  
  .section-title {
	font-size: 24px;
	color: #333;
	border-bottom: 2px solid #ccc;
	padding-bottom: 10px;
	margin-bottom: 20px;
  }
  
  .section-content {
	font-size: 18px;
	color: #555;
  }
  
  .section-content ol,
  .section-content ul {
	padding-left: 20px;
	margin-top: 10px;
	list-style-type: disc;
  }
  
  .section-content .nested-list {
	list-style-type: disc;
	padding-left: 20px;
  }
  
  .section-content li {
	margin-bottom: 10px;
	text-align: left;
  }
  
  .centered-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
  }
  .Main-liFont{
	font-size: 20px;
	font-weight: bold;
  }