.changepw-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.changepw-title {
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 20px;
  font-size: 28px;
  font-weight: bold;
  user-select: none;
}

.changepw-notification {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.changepw-context-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.passwordChange1,
.passwordChange2,
.passwordChange3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* 중앙 정렬 추가 */
  margin-bottom: 15px;
}

.passwordChange1 strong,
.passwordChange2 strong,
.passwordChange3 strong {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

input[type="password"] {

}
.passwordChange1 input, .passwordChange2 input, .passwordChange3 input{
  width: 100%; 
  max-width: 400px; 
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.2s;
}

input[type="password"]:focus {
  border-color: #50c2b5;
  outline: none;
}

.ChangeBtt-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ChangeBtt {
  background-color: #83D3C9;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 16px;
  font-weight: bold;
}

.ChangeBtt:hover {
  background-color: #50c2b5;
}

/* 반응형 스타일 */
@media screen and (max-width: 730px) {
  .changepw-context-container {
      padding: 15px;
  }

  .ChangeBtt {
      padding: 12px;
  }
}

@media screen and (max-width: 385px) {
  .changepw-title {
      font-size: 24px;
      padding: 15px;
  }

  .changepw-notification {
      margin-bottom: 20px;
  }

  .passwordChange1,
  .passwordChange2,
  .passwordChange3 {
      margin-bottom: 10px;
  }

  .ChangeBtt {
      padding: 10px;
      font-size: 14px;
  }
}
