.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
  width: 100%; 
}

.chart-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
  width: 100%;
}

.chart-item {
  background: #2c2c2c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(100% - 40px); 
  max-width: 350px; 
  box-sizing: border-box;
}

.date-picker-container {
  width: 100%;
  max-width: 350px; 
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  color: #fff;
  background: #83D3C9;
  padding: 10px 20px;
  margin: 5px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background: #50c2b5;
}

@media (max-width: 768px) {
  .chart-row {
    flex-direction: column;
  }
  .chart-container {
    height: auto; 
  }
}

@media (max-width: 1450px) {
  .chart-container {
    height: auto; 
  }
}

.chart-main {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.PatientChart-container {
  max-width: 1200px;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.chart-title {
  font-size: 2.5em;
  font-weight: 600;
}
.date-picker-container input{
  width: 20em;
}

@media screen and (max-width: 440px) {
  .date-picker-container input{
    width: 18em;
  }
}

@media screen and (max-width: 390px) {
  .date-picker-container input{
    width: 10em;
    margin-left: 20px;
  }
}
