.footer-label1{
  font-size: 14px;
  color: #7c8081;
  margin-left: 4px;
  margin-right:2px;
}

.footer-label2{
  font-size: 14px;
  color: #7c8081;
  margin-left: 4px;
}
.label1{
  font-size: 17px;
  color: #7c8081;
  font-weight: 600;
}
.copyright li:hover{
  font-weight: 500;
}