.cms-container {
  display: flex;
  min-height: 100vh;
}

.cms-main-content {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  margin: 30px auto;
}

.Cmss-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Cmss-chch {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 30px;
}

.Cmss-chch button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.Cmss-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.Cmss-select,
.Cmss-search,
.Cmss-options button {
  white-space: nowrap;
}

.Cmss-select {
  width: 18%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.Cmss-search {
  margin-right: 10px;
  margin-bottom: 10px;
}

.Cmss-content {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  display: block;
  cursor: pointer;
}

.Cmss-pagebtt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.Cmss-pagebtt button {
  margin: 10px 5px;
  padding: 7px 20px;
  background-color: rgb(74, 81, 93);
  border: 1px solid rgb(74, 81, 93);
  border-radius: 7px;
  cursor: pointer;
}

.Cmss-pagebtt button:hover {
  background-color: #686666;
}

.sang-bttcon {
  margin-left: 33%;
  margin-bottom: 10px;
}
#Notice-nofaq{
  background-color: white;
  color: black !important ;
  border: 1px solid #b0b0b0;
  font-weight: 500;
}
#cms-correction {
  margin-right: 10px;
}

.cms-QA {
  font-size: 20px;
  color: #e44c65;
  margin-right: 5px; /* 간격을 위한 마진 추가 */
}

.sang-title, .sang-description {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.Cms-noticefaqbtt{
  margin-left: 75%;
}

#saddasdasd{
  background-color: #ddd;
  color: #5e5d5d !important;
}

#saddasdasd:hover{
  background-color: #bbb;
}

/* .user-details {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
} */

/* 
.cmsuser-btt {
  background-color: #ddd;

}


.cmsuser-btt:hover {
  background-color: #bbb;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; 
  vertical-align: top;
} */
.user-details {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  position: relative;
}

.user-details p {
  margin: 10px 0; 
  padding: 10px 0; 
  border-bottom: 1px solid #e0e0e0;
}

.user-details p:last-child {
  border-bottom: none;
}

.cmsuser-bttt, .cmsuser-btt {
  background-color: #ddd;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.cmsuser-bttt:hover, .cmsuser-btt:hover {
  background-color: #bbb;
}

.cmsuser-bttt {
  color: green;
}

.cmsuser-btt {
  color: red;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.details-table td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: top;
}

.details-table td:first-child {
  width: 20%;
  font-weight: bold;
  text-align: left;
  color: #555;
}

.details-table td:last-child {
  width: 80%; 
  text-align: left;
}

@media (max-width: 768px) {
  .Cmss-chch {
    flex-direction: column;
    align-items: center;
  }

  .Cmss-chch button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .Cmss-options {
    flex-direction: column;
    align-items: center;
  }

  .Cmss-select,
  .Cmss-search,
  .Cmss-options button {
    width: 30%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  #saddasdasd {
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 580px) {
  .cms-main-content {
    width: 100%; 
  }
}
