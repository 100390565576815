#main {
  background-image: url("/public/images/dark-tl.svg"), url("/public/images/dark-tr.svg"), url("/public/images/dark-bl.svg"), url("/public/images/dark-br.svg");
  background-position: top left, top right, bottom left, bottom right;
  background-repeat: no-repeat;
  background-size: 25em;
  padding: 3em 0;
}

  #main > :last-child {
    margin-bottom: 0;
  }

  #main .sidebar section {
    border-top: solid 1px rgba(124, 128, 129, 0.2);
    margin: 3em 0 0 0;
    padding: 3em 0 0 0;
  }

    #main .sidebar section:first-child {
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
    }

body.index #main {
  padding-top: 5em;
}