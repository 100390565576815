.sidebar {
  background-color: transparent;
  border-bottom: 1px solid rgba(256, 256, 256, 0.3);
  min-height: 100px; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center; 
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 30px;
}
#sidebar-h2{
  margin-top: 80px;
}
.sidebar-title {
  user-select: none;
  margin-top: -5%;
  font-size: 20px;
  margin-bottom: 10px; 
  width: 200px;
  height: 180px;
  border-bottom: 4px solid #83D3C9;
}

.sidebar-menu {
  margin-top: 1%;
  display: flex; 
  justify-content: center;
  font-weight: bold;
  color: rgb(86, 85, 85);
}

.sidebar-menu li {
  margin-right: 20px; 
  cursor: pointer; 
  transition: color 0.3s;
}

.sidebar-menu li:hover {
  color: rgb(61, 61, 61); /* 마우스를 올렸을 때 텍스트 색상을 blue로 변경 */
}

/* .content {
  padding: 20px;
} */
