.patient-data {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .patient-data h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .data-section {
    margin-bottom: 20px;
  }
  
  .data-section h2 {
    color: #333;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .data-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .data-section li {
    background: #f9f9f9;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .data-section li:nth-child(odd) {
    background: #f1f1f1;
  }
  