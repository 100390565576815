.content-main {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.content-container {
  max-width: 1200px;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.major {
  text-align: center;
  margin-bottom: 20px;
}

.content-title {
  font-size: 2.5em;
  font-weight: 600;
}

.content-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.video-description-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.video-box {
  width: 100%;
  height: 200px;
}

.video-box iframe {
  width: 100%;
  height: 100%;
}

.description-box {
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
}

.description-box a {
  font-size: 1.2em;
  font-weight: bold;
  color: #83D3C9;
  text-decoration: none;
}

.description-box a:hover {
  text-decoration: underline;
}

.description-box p {
  font-size: 1em;
  color: #333;
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.page-button {
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
  border-radius: 3px;
  font-weight: bold;
  color: #575353;
}

.page-button:hover {
  background-color: #eee;
  border-color: #ccc;
}

.page-button.active {
  background-color: #83d3c9;
  color: #fff;
  border-color: #50c2b5;
  border: none;
  border-radius: 3px;
  font-weight: bold;
}
.content-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.video-description-wrapper {
  flex: 0 1 300px; /* 최소 300px, 필요 시 늘어남 */
  max-width: 300px; /* 최대 너비 제한 */
}

.video-box iframe {
  width: 100%; /* 부모 요소에 맞춤 */
  height: 180px; /* 비율에 맞춘 높이 */
}

.video-description-wrapper.single-video .video-box iframe {
  width: 80%; /* 단일 동영상일 경우 크기 축소 */
  height: 200px;
}
.category-filter {
  margin-bottom: 20px;
  display: flex; 
  justify-content: center; 
  align-items: center;
}
.description-css{
  font-weight: 500;
}



@media screen and (max-width: 730px) {
  .content-title {
    font-size: 2em;
  }
}

@media screen and (max-width: 385px) {
  .description-box a {
    font-size: 1em;
  }

  .description-box p {
    font-size: 0.9em;
  }

  .page-button {
    padding: 8px 12px;
    font-size: 0.9em;
  }
}
  