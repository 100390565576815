.Idppl-container{
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
    margin-top: 50px;
}
.Passwordppl-container{
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
    margin-top: 50px;
}
.aaaaaa{
    margin-top: 80px;
 }
.ddd{
    border-radius: 20px;

    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.075);
    margin-bottom: 10%;
}

.idemail {
    display: flex;
    margin-bottom: 10px; 
}
.idemail input{
    width: 20em;
}
.idemail2 input{
    width: 20em;
}
.idemail2{
    display: flex;
    margin-bottom: 10px;
}

.idfieldname, .idfield2 {
    flex-grow: 1;  
    margin-right: 10px; 
}

#email-btt {
    width: auto;       
    padding: 0 2% 0 2%; 
    font-size: 0.8em;  
    white-space: nowrap;
 
}
.idpass-nowrap {
    white-space: nowrap; 
    
}
.inzzngbubun{
    display: flex;
    margin-left: 14%;
}

#inzzngbubun-btt{
    margin-left: 3%;
}
#idckwrl-btt{
    font-size: 14px;
}
.namanane{
    color: blue;
}
#Ckw-btt,#Ckwpass-btt{
    padding: 0 20px;
    margin-left: 10px;
}
#Ckwpass-btt{
    background-color: #bbb;
    margin-left: 10px;
}
#Ckwpass-btt:hover{
    background-color: #9f9e9e;
}
.IdPassword-span{
    font-weight: bold;
    font-size: 15px;
}
@media (max-width: 768px) {
    #Ckw-btt,#Ckwpass-btt{
        line-height: 3em;
    }
}