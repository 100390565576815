.DiagnosisList-content {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  display: block;
  cursor: pointer;
}

#Diagnosis-addBtt {
  margin-right: 10px;
}

.X-Button {
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
}

.X-Button:hover {
  background-color: #ff1a1a;
}

#CmsAdd-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Cms-diagnosis-container, .Cms-medication-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
  color: #555;
}

.enhanced-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.enhanced-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.enhanced-list-item:last-child {
  border-bottom: none;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

/* .primary-button {
  padding: 10px 20px;
  background-color: #0275d8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.primary-button:hover {
  background-color: #025aa5;
}

.secondary-button {
  padding: 10px 20px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.secondary-button:hover {
  background-color: #4cae4c;
} */

@media (max-width: 768px) {
  .enhanced-section {
    width: 100%;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
  }

  #Diagnosis-addBtt, .primary-button, .secondary-button {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }
}
