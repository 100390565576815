/* Header */

#page-wrapper {
  /* padding-top: 3.5em; */
}

#header {
  background: #fff;
  /* background: white; */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.075);
  cursor: default;
  height: 3.3em;
  left: 0;
  line-height: 3.5em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

  #header h1 {
    height: inherit;
    left: 1.25em;
    line-height: inherit;
    margin: 0;
    position: absolute;
    top: 0;
    color: #7C8081;
    font-weight: bold;
    font-size: 22px;
  }
#header h1:hover{
  color: #83D3C9;
}
  #header nav {
    position: absolute;
    right: 1em;
    top: 0;
  }

    #header nav ul {
      margin: 0;
    }

      #header nav ul li {
        display: inline-block;
        margin-left: 1em;
      }

        #header nav ul li a, #header nav ul li span {
          border: 0;
          color: #656464;
          display: inline-block;
          height: inherit;
          line-height: inherit;
          outline: 0;
          font-weight: 500;
          font-size: 18px;
        }

          #header nav ul li a.button, #header nav ul li span.button {
            height: 2em;
            line-height: 2em;
            padding: 0 1.25em;
          }

          #header nav ul li a:not(.button):before, #header nav ul li span:not(.button):before {
            margin-right: 0.5em;
          }

        #header nav ul li.active > a, #header nav ul li.active > span {
          color: #e44c65;
        }

        #header nav ul li > ul {
          display: none;
        }
        #header nav li a{
          font-size: 1em;
        }
        #font-bold{
          font-weight: bold;
        }
body.landing #page-wrapper {
  padding-top: 0;
}

body.landing #header {
  background: transparent;
  box-shadow: none;
  position: absolute;
}
#header h1{
  margin-left: 150px;
}

/* 기존 스타일을 보존하고, 새 스타일을 추가합니다 */
#nav ul.submenu {
  color: #000; /* 원하는 색상으로 변경하세요 */
}

#nav ul.submenu li.comusub a {
  color: #000; /* 원하는 색상으로 변경하세요 */
}

#nav ul.submenu li.comusub a:hover {
  color: #83D3C9; /* 마우스 오버 시 색상을 변경하려면 여기에 추가하세요 */
}
/* 기본 서브메뉴 스타일 */
/* .submenu {
  position: absolute;
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
} */

/* 로그인 상태에 따른 서브메뉴 스타일 */
/* .submenu-logged-in {
  left: 100%; 
  top: 0; 
} */
/* 커뮤니티 메뉴가 활성화될 때 서브메뉴 표시 */
/* .navPanel-visible .submenu {
  display: block;
} */
.submenu {
	display: flex;
	flex-direction: column; 
	position: absolute;
	top: 70%;
	left: 33%;
	padding: 10px;
	z-index: 1000;
  }
  .submenu-logged-in{
    display: flex;
    flex-direction: column; 
    position: absolute;
    top: 70%;
    left: 44%;
    padding: 10px;
    z-index: 1000;
  }
  .submenu li {
	list-style: none;
  }
  .submenu-logged-in li{
    list-style: none;
  }

 #nav ul li a:hover{
  color: #83D3C9;
  font-weight: bold;
 }

