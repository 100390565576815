.cms-container {
  display: flex;
  min-height: 100vh;
}

.cms-main-content {
  flex-grow: 1;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  
}

.centered-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Cms-form-group {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;
}

.Cms-form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.Cms-form-group input, .Cms-form-group textarea, .Cms-form-group select {
  width: 100%;

  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 0.5rem;
}

.option-input {
  display: flex;
  align-items: center;
}

.option-input input {
  flex: 1;
  margin-right: 0.5rem;
}

.option-input button {
  padding: 0.5rem 1rem;
  background-color: #83d3c9;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option-input button:hover {
  background-color: #50c2b5;
}



.delete-button {
  background-color: #e44c65;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #c43b55;
}

.Cms-form-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}


#major-rest {
  margin-bottom: 1.5rem;
  text-align: center;
}
#CIST-canclebtt{
  background-color: #bbb;
}

#CIST-canclebtt:hover{
  background-color: #9e9e9e;
}
@media (max-width: 600px) {
  .Cms-form-group {
    width: 100%;
    padding: 0 1rem;
  }
}
.question-image img {
  max-width: 100%; /* 부모 컨테이너 너비에 맞춤 */
  height: auto; /* 비율을 유지하며 높이 자동 조절 */
  max-height: 400px; /* 최대 높이 설정 */
  object-fit: contain; /* 이미지 비율 유지하며 조절 */
}
.questions-cistc {
  border-top: 1px solid black;
}