.qnaup-page {
  width: 60%;
  margin: 0 auto;
  font-size: 16px;
  margin-bottom: 5%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.qnaup-header {
  padding: 2% 5%;
  margin-top: 160px;
}

.qnacontent-container {
  padding: 2% 0 0;
  color: #333;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.QnAup-title {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 1.2em;
}

.QnAup-author {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  font-size: 0.9em;
  color: #888;
}

.qnaup-page span {
  margin-left: 30px;
  font-size: 16px;
  color: #555;
}

.qnacontent-container img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

#edit-title-input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 1em;
}

#qnaupdate-title {
  margin-right: 10px;
}

.qna-comment-section {
  margin-top: 20px;
  padding: 0 20px;
}

.qna-comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.qna-comment-user {
  font-weight: bold;
  color: #0073e6;
}

.qna-comment-content {
  margin: 5px 0;
  color: #555;
}

.qna-comment-date {
  display: inline-block;
  margin-right: 10px;
  color: #888;
}

.qna-comment-actions {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.qna-answers {
  margin-top: 20px;
  padding: 0 20px;
}

.qna-answer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.qna-answer-user {
  font-weight: bold;
  color: #0073e6;
}

.qna-answer-content {
  margin: 5px 0;
  color: #555;
}

.qna-answer-date {
  color: #888;
}

.qna-answer-actions {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

#QnA-CommentInputs {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
  flex-wrap: wrap; /* 줄 바꿈 추가 */
}

#QnA-Input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  min-width: 0; /* 최소 너비 설정 */
}

#QnA-CommentButton {
  margin-left: 10px;
  padding: 0px 10px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  background-color: #83D3C9;
  color: white;
  cursor: pointer;
  flex-shrink: 0; 
  margin-bottom: 10px;
}

#QnA-CommentButton:hover {
  background-color: #83d3c9;
}
#button-del{
  background-color: #ddd;
}
#button-del:hover{
  background-color: #bbb;
}
.QnAup-contentBtt {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
  background-color: #f9f9f9;
}

.QnAup-contentBtt button {
  padding: 0px 20px;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  background-color: #ff9696;
  color: white;
  cursor: pointer;
}

.QnAup-contentBtt button:hover {
  background-color: #ffb0b0;
}

@media (max-width: 768px) {
  .qnaup-page {
    width: 80%; 
  }
  #QnA-CommentInputs {
    flex-direction: column;
  }

  #QnA-Input {
    margin-bottom: 10px;
    margin-right: 0; /* 오른쪽 마진 제거 */
    width: 100%; /* 전체 너비 사용 */
  }

  #QnA-CommentButton {
    margin-left: 0;
    width: 100%; /* 전체 너비 사용 */
  }

}
@media (max-width: 480px) {
  .qnaup-page {
    width: 90%; /* 더 작은 화면에서 컨테이너 크기를 90%로 설정 */
  }
}


.QnA-commentInput{
  padding: 10px;
}