.CIST-content {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    display: block;
    cursor: pointer;
}

.CIST-AddBtt {
    float: right;
}
#cancle-btt{
    background-color: #bbb;
}
.QuestionDetail-content {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 60%;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.QuestionDetail-content h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.QuestionDetail-content p {
    font-size: 1.2em;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .QuestionDetail-content {
        width: 90%;
    }

    .QuestionDetail-buttons {
        flex-direction: column;
        align-items: center;
    }

    .QuestionDetail-buttons .button {
        margin: 10px 0;
        width: 100%;
        max-width: 200px;
    }
}

.questions-cistc img {
    max-width: 100%; 
    height: auto;   
    display: block;  
    margin: 0 auto;   
  }
  
  .question-item {
    overflow: auto; 
  }
  