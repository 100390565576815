.diagnosis-container{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-bottom: 100px;
    
}

.diagnosis-title{
    margin-bottom: 20px;
    border-bottom: 1px solid whitesmoke;
    width: 500px;
    text-align: center;
    padding: 50px;
    font-size: 35px;
    font-weight: 1000;
    user-select: none; 
}
.patient-div{
    margin-bottom: 25px;
}
.patient-name{
    font-size: 25px;
}
.medication-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.medication-name {
    width: 100%; 
    margin-bottom: 5px; 
}

#Drug-width{
    width: 30%;
}
.Drug-name{
    margin-top: 20px;
}
.dosage{
    margin-right: 10px;
}

.Diagnosis-Addbtt{
    margin-top: 30px;
    
}
#Diagnosis-btt,#Medication-btt,#cancel-btt{
    width: 9em;
}
#cancel-btt{
    color: #606060;
    background-color: #ddd;
    font-weight: bold;
    border: none;

}
#cancel-btt:hover{
    background-color: #bbb;
}
#Diagnosis-btt{
    margin-top: 10px;
    align-items: start;
    width: 90%;
}
#Medication-btt{
    margin-top: 10px;
    width: 90%;
}
#cancel-btt{
    margin-top: 10px;
    width: 90%;
}
#patientNaem{
    font-size: 25px;
    font-weight: 500;
    color: #5c5c5c;
}
#disease-input,#Drug-width2{
    width: 90%;
}
@media (max-width: 450px) {
    .medication-name, .dosage, .frequency, .button {
        width: 100%; 
        min-width: 40%; 
        line-height: 2em;
    }

    .medication-container, .daskdkasdk > div {
        flex-direction: column; 
    }
    .medication-name, .dosage, .frequency {
        flex: 1;  
    }
    .diagnosis-title {
        padding: 10px; 
    }
    #disease-input{
        width: 90%;
        margin-right: 100px;
    }
    #disease-btt{
        width: 90%;
    }
    .Drug-name{
        width: 90%;
  
    }
    #Drug-width2{
        width: 90%;
    }
}
.diagnosis-box{
    /* margin-left: 5%; */
    margin: 0 auto;
    background: white;
    max-width: 500px;
    border-radius: 20px;
    padding: 30px;
}