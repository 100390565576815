/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center; 
  
} */
.nav-pills-step {
  display: flex;
  list-style: none;
  padding: 20px;
  justify-content: center;
  align-items: center; 
}
.nav-item {
  /* background-color: rgb(185, 182, 182); */
  background-color: white;
  border: 1px solid rgb(229, 229, 229);
  color: black;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
}
.nav-item.active {
  background-color: #83d3c9;
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.num {
  margin-right: 5px;
}
.section-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* height: 90vh; */
  margin-top: 8%;
} 
#section3-container{
  margin-bottom: 5.1em;
}
#section3-btt{
  font-size: 15px;
  font-weight: bold;
}
.pscheck-box1{
  padding-bottom: 15px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 1%;
}

#section2-id{
  /* margin-bottom: -18px; */
}
.pschecks{
  color: red;
  font-weight: bold;
}
/* .Section-btt{
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  border: 0 none;
  border-radius: 20px;
  padding: 10px 20px;
  color: #fff;
  margin-top: 10px;
  background-color: dodgerblue;
} */

.nextBtt{
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  border: 0 none;
  padding: 10px 20px;
  color: #fff;
  background-color: #83d3c9;
  margin-top: 20px;
  border-radius: 4px;
}
.nextBtt:hover{
  background-color:  #50c2b5;
}

/* .Allcheckdss{
  padding-top: 7px;
  font-size: 18px;
  font-weight: bold;
} */


@media only screen and (max-width: 768px) {
  .section-container{
    margin-top: 40%;
  }
}

@media only screen and (max-width: 200px) {
  .section-container{
    margin-top: 12%;
  }
}

