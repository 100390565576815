
.no-email-collection-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
.terms-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  .FooMenu-container {
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: auto;
    margin-top: 50px;

  }

  .Fooh2CSS{
    color: black;
    font-weight: 500;
  }